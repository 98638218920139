<template>
  <div class="coupons">
    <div class="title">优惠券</div>
    <div class="cell">
      <div class="titles">
        <div>优惠券列表</div>
        <div>
          <el-button
            @click="handleCreate('add', 0)"
            icon="el-icon-plus"
            size="medium"
            type="primary"
            >创建</el-button
          >
        </div>
      </div>
      <div class="tabs">
        <div class="tabs-head">
          <div
            @click="handleQH(i)"
            class="item"
            :class="index == i ? 'item-active ' : ''"
            v-for="(item, i) in tabHeadInfo"
            :key="i"
          >
            {{ item }}
          </div>
        </div>
        <div class="tabs-content">
          <div class="list">
            <div class="list-head">
              <div style="width: 15%">优惠码|名称</div>
              <div style="width: 15%">优惠类型</div>
              <div style="width: 15%">折扣金额</div>
              <div style="width: 15%">使用限制</div>
              <div style="width: 10%">已使用</div>
              <div style="width: 20%">状态|优惠券领取时间</div>
              <div style="width: 10%">操作</div>
            </div>
            <div v-if="httpsShow">
              <div v-if="listData.list && listData.list.length != 0">
                <div
                  class="list-content"
                  v-for="item in listData.list"
                  :key="item.id"
                >
                  <div style="width: 15%; display: flex">
                    <div>
                      <img
                        style="width: 60px; height: 60px; margin-right: 10px"
                        :src="item.coupon_image"
                        alt=""
                      />
                    </div>
                    <div>
                      <div>{{ item.coupon_code }}</div>
                      <div style="color: #b0b0b0; margin-top: 4px">
                        {{ item.coupon_name }}
                      </div>
                    </div>
                  </div>
                  <div style="width: 15%">
                    <div>
                      {{
                        item.coupon_type == 1
                          ? "店铺优惠券"
                          : item.coupon_type == 2
                          ? "商品优惠券"
                          : ""
                      }}
                    </div>
                    <div style="color: #b0b0b0; margin-top: 4px">
                      {{ item.coupon_type == 1 ? "(全部商品)" : "(指定商品)" }}
                    </div>
                  </div>
                  <div style="width: 15%">
                    {{
                      item.discount_coupon_type == 1
                        ? "RM" + item.discount_amount
                        : item.discount_amount + "折"
                    }}
                  </div>
                  <div style="width: 15%">{{ item.available_number }}</div>
                  <div style="width: 10%">{{ item.used_number }}</div>
                  <div style="width: 20%">
                    <div class="tag" v-if="item.status == '进行中的活动'">
                      进行中的活动
                    </div>
                    <div class="tag1" v-if="item.status == '接下来的活动'">
                      接下来的活动
                    </div>
                    <div class="tag2" v-if="item.status == '已过期'">
                      已过期
                    </div>
                    <div style="margin-top: 10px">
                      {{ item.start_time | formatTime }} -
                    </div>
                    <div>{{ item.end_time | formatTime }}</div>
                  </div>
                  <div style="width: 10%">
                    <div
                      class="btn"
                      v-if="item.status == '已过期'"
                      @click="handlePush('info', item.id)"
                    >
                      详情
                    </div>
                    <div
                      class="btn"
                      v-else
                      @click="handlePush('edit', item.id)"
                    >
                      编辑
                    </div>
                    <div class="btn" @click="handleCreate('copy', item.id)">
                      复制
                    </div>
                    <div class="btn" @click="handlePush('order', item.id)">
                      订单
                    </div>
                    <div
                      class="btn"
                      v-if="item.status == '进行中的活动'"
                      @click="handleDel(item.id, 1)"
                    >
                      结束
                    </div>
                    <div
                      class="btn"
                      v-if="item.status == '接下来的活动'"
                      @click="handleDel(item.id, 2)"
                    >
                      删除
                    </div>
                  </div>
                </div>
              </div>
              <div
                v-else
                style="text-align: center; color: #b0b0b0; line-height: 100px"
              >
                暂无
              </div>
            </div>
            <div v-else style="text-align: center; padding-top: 20px">
              <img :src="lodingImg" alt="" />
            </div>
          </div>
        </div>
      </div>
      <div style="text-align: right; padding: 20px">
        <el-pagination
          background
          layout="prev, pager, next"
          :total="listData.total"
          :page-size="page_size"
          :current-page="page"
          @current-change="change"
        >
        </el-pagination>
      </div>
    </div>
  </div>
</template>
<script>
import { couponList, deleteCoupon } from "@/api/marketing.js";
export default {
  data() {
    return {
      tabHeadInfo: ["全部", "进行中的活动", "接下来的活动", "已过期"],
      index: 0,
      listData: [],
      page: 1,
      page_size: 10,
      httpsShow: false,
      lodingImg: require("@/assets/img/app/http.gif"),
    };
  },
  created() {
    this.getList();
  },
  methods: {
    getList(type) {
      this.httpsShow = false;
      couponList({
        status: this.index,
        page: this.page,
        page_size: this.page_size,
      }).then((res) => {
        this.httpsShow = true;
        if (res.code == 1) {
          this.listData = res.data;
          this.listData.list.forEach((item) => {
            if (type) {
              item.status = "已过期";
            }
            if (item.discount_coupon_type == 2) {
              item.discount_amount = item.discount_amount / 10;
            }
          });
        }
      });
    },
    handleQH(val) {
      this.page = 1;
      this.index = val;
      this.getList();
    },
    handleCreate(type, id) {
      sessionStorage.COUPONSTYPE = type;
      sessionStorage.COUPONSID = id;
      this.$router.options.routes.forEach((item) => {
        if (item.name == "marketingCenters") {
          item.children.forEach((val) => {
            if (val.name == "createCoupons") {
              val.meta.bread[3].name = "创建优惠券";
            }
          });
        }
      });

      this.$router.push("createCoupons");
    },
    handlePush(type, id) {
      if (type == "edit" || type == "info") {
        sessionStorage.COUPONSTYPE = type;
        sessionStorage.COUPONSID = id;

        this.$router.options.routes.forEach((item) => {
          if (item.name == "marketingCenters") {
            item.children.forEach((val) => {
              if (val.name == "createCoupons") {
                val.meta.bread[3].name =
                  type == "edit" ? "编辑优惠券" : "优惠券详情";
              }
            });
          }
        });
        this.$router.push("createCoupons");
      }
      if (type == "order") {
        sessionStorage.COUPONSID = id;
        this.$router.push("couponsOrder");
      }
    },
    handleDel(id, type) {
      let _this = this;
      let text = type == 1 ? "结束" : "删除";
      this.$confirm("确定" + text + "该优惠券吗?", text + "优惠券", {
        confirmButtonText: text,
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          deleteCoupon({
            id: id,
            type: type,
          }).then((res) => {
            if (res.code == 1) {
              this.$message({
                type: "success",
                message: text + "成功!",
              });

              if (type == 1) {
                this.page = 1;
                this.index = 3;
              }
              // _this.listData=""
              _this.getList(true);
              // _this.$forceUpdate()
            }
          });
        })
        .catch(() => {
          console.log("取消");
        });
    },
    change(page) {
      this.page = page;
      this.getList();
    },
  },
};
</script>
<style lang="less" scoped>
.coupons {
  width: 1400px;
  margin: 0 auto;
  padding-top: 20px;

  .title {
    font-size: 20px;
  }
  .cell {
    padding: 30px;
    background: #fff;
    margin-top: 20px;
    .titles {
      font-size: 18px;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    .tabs {
      .tabs-head {
        display: flex;
        border-bottom: 1px solid #eee;
        .item {
          padding: 16px 20px;
          font-size: 14px;
          color: #000;
          border-bottom: 4px solid transparent;
          cursor: pointer;
          margin-right: 10px;
        }
        .item-active {
          color: #ee4d2d;
          border-bottom: 4px solid #ee4d2d;
        }
      }
      .tabs-content {
        .list {
          font-size: 14px;
          border: 1px solid #eee;
          border-radius: 4px;
          margin-top: 20px;
          .list-head {
            display: flex;
            justify-content: space-between;
            background: #f6f6f6;
            padding: 10px 20px;
            color: #858585;
          }
          .list-content {
            padding: 20px 0 10px;
            margin: 0 20px;
            display: flex;
            justify-content: space-between;
            font-size: 12px;
            border-bottom: 1px solid #eee;
            .tag {
              background: #eaf9ef;
              font-size: 12px;
              display: initial;
              color: #56cc78;
              padding: 2px 4px;
              border-radius: 2px;
            }
            .tag1 {
              background: #fcecf0;
              font-size: 12px;
              display: initial;
              color: #ee4d2d;
              padding: 2px 4px;
              border-radius: 2px;
            }
            .tag2 {
              background: #eee;
              font-size: 12px;
              display: initial;
              color: #6b6866;
              padding: 2px 4px;
              border-radius: 2px;
            }
            .btn {
              color: #367ddf;
              margin-bottom: 6px;
              cursor: pointer;
            }
          }
        }
      }
    }
  }
}
</style>